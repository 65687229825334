<template>
<div>
    <p>{{name}}</p>
    <!-- <vue-plyr>
    </vue-plyr>   -->
        <audio controls playsinline autoplay :src="src" ref="audio">
        </audio>

</div>
</template>

<script>
export default {
    name:"Player",
    data(){
        return {
            name:"",
            src:""
        }
    },
    props:{
        currSrc:{
            type:String,
            default:""
        }
    },
    methods:{
        play(opt){
            //强制重新播放
            this.name = opt.name;
            this.src = opt.src
            this.$nextTick(()=>{
                //设置播放时间到0
                this.$refs.audio.currentTime = 0
                this.$refs.audio.play()
            })
        }
    }
}
</script>

<style>

</style>